export const REACT_APP_ENVIRON = process.env.REACT_APP_ENVIRON
  ? process.env.REACT_APP_ENVIRON
  : 'staging';

let auth0Domain;
let auth0ClientId;
let graphqlEndpoint;
let cognitoIDPool;
if (REACT_APP_ENVIRON === 'production') {
  auth0Domain = 'missionportal.us.auth0.com';
  auth0ClientId = 'kVsC4ZyVitGajGbFlrpMH23y3nU0l3kx';
  graphqlEndpoint = 'https://api.missionportal.com/graphql';
  cognitoIDPool = 'us-east-1:da1de39a-9658-4835-bddc-c1a64eae1c3c';
} else if (REACT_APP_ENVIRON === 'staging') {
  auth0Domain = 'missionportal-staging.us.auth0.com';
  auth0ClientId = 'mYy0BcL7CIpM08AqJPzCB98Lpj6MAHNf';
  graphqlEndpoint = 'https://oesxty5uovcudkobxyhvgzi4su.appsync-api.us-east-1.amazonaws.com/graphql';
  cognitoIDPool = 'us-east-1:3728ebbd-1844-4c49-afca-826b228715e1';
} else {
  auth0Domain = 'missionportal-dev.us.auth0.com';
  auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
  cognitoIDPool = process.env.REACT_APP_COGNITO_ID_POOL;
}

// MissionPortal-DB audience has offline_access enabled.
// This allows us to use refresh tokens and rotate them.
export const authconfig = {
  domain: auth0Domain,
  clientId: auth0ClientId,
  audience: 'MissionPortal-DB',
};

export const awsconfig = {
  aws_project_region: 'us-east-1',
  aws_appsync_graphqlEndpoint: graphqlEndpoint,
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'OPENID_CONNECT',
  aws_cognito_identity_pool_id: cognitoIDPool,
  aws_cognito_region: 'us-east-1',
};

const dromoFrontendLicense = {
  production: '9cce1b92-9df8-4b6d-904b-40f231513101',
  staging: '171ccc59-1c10-434d-9a39-e4ea3bba0445',
};

export const dromoFrontendLic = REACT_APP_ENVIRON === 'production'
  ? dromoFrontendLicense.production
  : dromoFrontendLicense.staging;

// expires on Jun 8, 2025
export const MuiPremiumLic = '6e52e51df7e8b853e2a23bf7c5d6d26bTz05MjA1NCxFPTE3NDk0MDQwNzAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y';
